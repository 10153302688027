// Define the Roman-character translations for special-character urls
// Category names(left) paired with roman-character urls(right)

const urls = {
  内心的挣扎: 'neixin-de-zhengzha',
  父神: 'fu-shen',
  经营人生: 'jingying-rensheng',
  向前迈进: 'xiang-qian-maijin',
  圣子耶稣: 'sheng-zi-yesu',
  社会与文化: 'shehui-yu-wenhua',
  死亡与永恒: 'siwang-yu-yongheng',
};

module.exports = {
  urls: urls,
};
