import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { pageLink, subtitle, dots } from '../components/layout.module.css';
import { SEO } from '../components/seo';
import Layout from '../components/layout';
import CategoryButtons from '../components/categoryButtons';
import dotImage from '../images/dots-light.png';

// Template used for rendering the "topics" page, creates list of articles and uses button component

const meta = {
  title: '话题',
  description: '',
};

const CategoryList = ({ pageContext, data }) => (
  <Layout meta={meta} pageTitle={meta.title}>
    <h1>主题和问题</h1>
    <p className={subtitle}>类别</p>
    <CategoryButtons />
    <img className={dots} src={dotImage} alt="dots" />
    <p className={subtitle}>{pageContext.category}</p>
    {/* Pull in articles and map to buttons */}
    {data.allMdx.nodes.map((node) => {
      return (
        <article className={pageLink} key={node.frontmatter.title}>
          <Link to={`/${node.frontmatter.slug}`}>
            {node.frontmatter.title}
            <i></i>
          </Link>
        </article>
      );
    })}
    <img className={dots} src={dotImage} alt="dots" />
  </Layout>
);

export const query = graphql`
  query CategoryListQuery($ids: [String]) {
    allMdx(filter: { id: { in: $ids } }) {
      nodes {
        frontmatter {
          slug
          title
        }
        id
        slug
        body
      }
    }
  }
`;

export default CategoryList;

export const Head = (pageContext) => <SEO pageContext={pageContext} />;
