import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby';
import { categoryLinks, active } from './layout.module.css';
import { urls } from './translations';

const CategoryButtons = ({ children, pageTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          body
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            template
            category
          }
          excerpt
          wordCount {
            words
          }
          id
          tableOfContents
          fileAbsolutePath
        }
      }
    }
  `);

  function dedupeCategories(allMdx) {
    const uniqueCategories = new Set();
    // Iterate over all articles
    allMdx.nodes.forEach((node) => {
      // Iterate over each category in an article
      if (node.frontmatter && node.frontmatter.category) {
        node.frontmatter.category.forEach((category) => {
          uniqueCategories.add(category);
        });
      }
    });
    // Create new array with duplicates removed
    return Array.from(uniqueCategories);
  }

  // Create array of every category without duplicates
  const dedupedCategories = dedupeCategories(data.allMdx);

  let windowRef = '/huati/';

  if (typeof window !== 'undefined') {
    windowRef = window.location.href;
  }

  return (
    <div className={categoryLinks}>
      <Link
        to="/huati"
        className={!windowRef.includes('/huati/') ? active : 'inactive'}
      >
        全部
      </Link>
      {dedupedCategories.map((node) => (
        <article key={node}>
          <Link
            to={'/huati/' + urls[node]}
            className={windowRef.includes(urls[node]) ? active : 'inactive'}
          >
            {node}
          </Link>
        </article>
      ))}
    </div>
  );
};

export default CategoryButtons;
